import { createRouter, createWebHistory } from "vue-router";

// Function to check if the user is logged in
function isLoggedIn() {
  return localStorage.getItem("clientaccesstoken") !== null;
}

const routes = [
  // {
  //     path: "/",
  //     name: "SuperDashboard",
  //     component: () =>
  //         import ("@/components/SuperAdminDashboard.vue"),
  //     meta: { allowAnonymous: true },
  // },
  {
    path: "/signin",
    name: "signinComponent",
    component: () => import("@/components/Login/SignIn.vue"),
    meta: { allowAnonymous: true },
  },
  {
    path: "/signup",
    name: "signupComponent",
    component: () => import("@/components/Login/SignUp.vue"),
    meta: { allowAnonymous: true },
  },
  {
    path: "/comfirmpin/:email",
    name: "PinConfirmComponent",
    component: () => import("@/components/Login/ConfirmPin.vue"),
    meta: { allowAnonymous: true },
  },
  {
    path: "/resetPassword/:pin",
    name: "resetPasswordComponent",
    component: () => import("@/components/ResetPassword/ResetPasswordForm.vue"),
    meta: { allowAnonymous: true },
  },
  {
    path: "/security-ques/complete/registration",
    name: "SecurityAndPasswordComp",
    component: () => import("@/components/Login/SecurityAndPasswordComp.vue"),
    meta: { allowAnonymous: true },
  },
  {
    path: "/security-ques",
    name: "SecurityQuestionComp",
    component: () => import("@/components/Login/SecurityQuestionComp.vue"),
    meta: { allowAnonymous: true },
    props: (route) => ({ email: route.query.email }),
  },
  {
    path: "/",
    name: "DashboardSkeleton",
    component: () => import("@/components/MainLayout/DashboardSkeleton.vue"),
    meta: { requiresAuth: true },
    children: [
      // ============================================= NEW URLS ================================
      {
        path: "/",
        name: "Dashboard",
        component: () => import("@/components/Dashboard/NewDashboard.vue"),
        meta: { allowAnonymous: true },
      },
      {
        path: "/time-studies",
        name: "TimeStudies",
        component: () => import("@/components/TimeStudies/NewTimeStudies.vue"),
        meta: { allowAnonymous: true },
      },
      {
        path: "/task-manager",
        name: "TaskManager",
        component: () => import("@/components/TaskManager/NewTaskManager.vue"),
        meta: { allowAnonymous: true },
      },
      {
        path: "/create-transplant-task",
        name: "CreateTransplantTask",
        component: () =>
          import("@/components/TaskManager/CreateTransplantTasks.vue"),
        meta: { allowAnonymous: true },
      },
      {
        path: "/create-faculty-task",
        name: "CreateFacultyTask",
        component: () =>
          import("@/components/TaskManager/CreateFacultyTasks.vue"),
        meta: { allowAnonymous: true },
      },
      {
        path: "/people",
        name: "People",
        component: () => import("@/components/People/NewPeopleCom.vue"),
        meta: { allowAnonymous: true },
      },
      {
        path: "/management",
        name: "Management",
        component: () => import("@/components/Management/NewManage.vue"),
        meta: { allowAnonymous: true },
      },
      {
        path: "/settings",
        name: "Settings",
        component: () => import("@/components/SystemSettings/NewSetting.vue"),
        meta: { allowAnonymous: true },
      },
      {
        path: "/total-reports",
        name: "TotalReports",
        component: () => import("@/components/Reports/NewReports.vue"),
        meta: { allowAnonymous: true },
      },
      {
        path: "/system-admin-reports",
        name: "SystemAdminReports",
        component: () => import("@/components/Reports/SystemAdminReports.vue"),
        meta: { allowAnonymous: true },
      },
      {
        path: "/reports-details/:id",
        name: "ReportsDetails",
        component: () => import("@/components/Reports/NewReportsDetails.vue"),
        meta: { allowAnonymous: true },
      },
      {
        path: "/rates",
        name: "TotalRates",
        component: () => import("@/components/Rates/RatesCom.vue"),
        meta: { allowAnonymous: true },
      },
      {
        path: "/help",
        name: "Help",
        component: () => import("@/components/Help/NewHelp.vue"),
        meta: { allowAnonymous: true },
      },
      // ============================================= NEW URLS ================================
      // {
      //   path: "/",
      //   name: "SuperAdminDashboard",
      //   component: () =>
      //     import("@/components/Dashboard/SuperAdminDashboard.vue"),
      // },
      // {
      //   path: "/",
      //   name: "SuperAdminDashboard",
      //   component: () =>
      //     import("@/components/Dashboard/SuperAdminDashboard.vue"),
      // },
      {
        path: "/main-tasks",
        name: "MyTasks",
        component: () =>
          import("@/components/TaskManager/TransplantTasks/MyTasks.vue"),
      },
      {
        path: "/transplant-assign-tasks",
        name: "MyTasksTwo",
        component: () =>
          import("@/components/TaskManager/TransplantTasks/MyTaskTwo.vue"),
      },
      {
        path: "/faculty-my-tasks",
        name: "FacultyMyTasks",
        component: () =>
          import("@/components/TaskManager/FacultyTasks/FacultyMyTasks.vue"),
      },
      {
        path: "/faculty-assign-tasks",
        name: "FacultyMyTasksTwo",
        component: () =>
          import("@/components/TaskManager/FacultyTasks/FacultyMyTaskTwo.vue"),
      },
      {
        path: "/alternatives-weeks",
        name: "AlternativesWeeks",
        component: () =>
          import("@/components/TaskManager/AlternativesWeeks.vue"),
      },
      // {
      //   path: "/departments",
      //   name: "AllDepartments",
      //   component: () => import("@/components/Departments/DepartmentsComp.vue"),
      // },

      // {
      //   path: "/organization",
      //   name: "AllOrganization",
      //   component: () =>
      //     import("@/components/Organization/OrganizationComp.vue"),
      // },
      {
        path: "/reimbursement-rates",
        name: "reimbursement-rates",
        component: () =>
          // import("@/components/Reports/CustomReports/CustomReports.vue"),
          import("@/components/Reports/FinalReportSuperAdd.vue"),
      },
      {
        path: "/financial-reports",
        name: "financial-reports",
        component: () =>
          // import("@/components/Reports/CustomReports/CustomReports.vue"),
          import("@/components/Reports/FinancialReports.vue"),
      },
      {
        path: "/managetasks",
        name: "ManageTasks",
        component: () =>
          import(
            "@/components/TaskManager/ManageAssignTasks/AssignedTasksList.vue"
          ),
      },
      {
        path: "/managetasks",
        name: "ManageTasks",
        component: () =>
          import(
            "@/components/TaskManager/ManageAssignTasks/AssignedTasksList.vue"
          ),
      },
      {
        path: "/createtasks",
        name: "CreateTasks",
        component: () =>
          import("@/components/TaskManager/TransplantTasks/CreateTasks.vue"),
      },
      {
        path: "/faculty-create-tasks",
        name: "FacultyCreateTasks",
        component: () =>
          import(
            "@/components/TaskManager/FacultyTasks/FacultyCreateTasks.vue"
          ),
      },
      {
        path: "/manage",
        name: "Manage",
        component: () =>
          import(
            "@/components/TaskManager/ManageAssignTasks/FacultyAssignTask.vue"
          ),
      },
      {
        path: "/manage-transplant",
        name: "ManageTransplant",
        component: () =>
          import(
            "@/components/TaskManager/ManageAssignTasks/TransplantAssignTask.vue"
          ),
      },
      {
        path: "/managetasksA",
        name: "ManageTasksA",
        component: () =>
          import("@/components/TaskManager/ManageTask/ManageTaskList.vue"),
      },
      {
        path: "/manage-organs",
        name: "ManageOrgans",
        component: () =>
          import("@/components/Management/ManageOrgans/ManageOrgans.vue"),
      },
      {
        path: "/manage-categories",
        name: "ManageCategories",
        component: () =>
          import(
            "@/components/Management/ManageCategories/ManageCategories.vue"
          ),
      },
      {
        path: "/create-categories",
        name: "CreateCategories",
        component: () =>
          import(
            "@/components/Management/ManageCategories/CreateCategories.vue"
          ),
      },
      {
        path: "/manage-organization",
        name: "ManageOrganization",
        component: () =>
          import(
            "@/components/Management/ManageOrganizations/ManageOrganization.vue"
          ),
      },
      {
        path: "/manage-department",
        name: "ManageDepartment",
        component: () =>
          import(
            "@/components/Management/ManageDepartment/ManageDepartment.vue"
          ),
      },
      {
        path: "/manage-facility",
        name: "ManageFacility",
        component: () =>
          import("@/components/Management/ManageFacility/ManageFacility.vue"),
      },
      {
        path: "/manage-upcomingEvents",
        name: "ManageUpcomingEvents",
        component: () =>
          import(
            "@/components/Management/ManageUpcomingEvents/ManageUpcomingEvents.vue"
          ),
      },
      {
        path: "/manage-sso-identities",
        name: "ManageSsoIdentity",
        component: () =>
          import(
            "@/components/Management/ManageSsoIdentity/ManageSsoIdentity.vue"
          ),
      },

      // {
      //   path: "department-head",
      //   name: "Departments-Head",
      //   component: () =>
      //     import("@/components/DepartmentsHead/DepartmentHeadComp.vue"),
      // },
      // {
      //   path: "tasks",
      //   name: "Tasks",
      //   component: () => import("@/components/Tasks/TasksComp.vue"),
      // },
      // {
      //   path: "users",
      //   name: "Users",
      //   component: () => import("@/components/Users/UsersComp.vue"),
      // },

      {
        path: "reports",
        name: "Reports",
        component: () => import("@/components/Reports/ReportsComp.vue"),
      },
      {
        path: "account-setting",
        name: "AccountSetting",
        component: () =>
          import("@/components/AccountSettings/AccountSettingComp.vue"),
      },
      {
        path: "user-account",
        name: "UserAccount",
        component: () =>
          import("@/components/UserAccountProfile/UserAccountComp.vue"),
      },
      {
        path: "recent-records",
        name: "OutstandingRecords",
        component: () =>
          import(
            "@/components/TimeStudies/OutstandingRecords/OutstandingRecords.vue"
          ),
      },

      {
        path: "outstanding-admin-reports",
        name: "OutstandingAdminReports",
        component: () =>
          import(
            "@/components/TimeStudies/OutstandingRecords/OutStandingReports.vue"
          ),
      },
      {
        path: "view-recent-records/:id",
        name: "ViewOutstandingRecords",
        component: () =>
          import(
            "@/components/TimeStudies/OutstandingRecords/viewOutstandingRecords.vue"
          ),
      },
      {
        path: "view-recent-faculty-records/:id",
        name: "ViewOutstandingFacultyRecords",
        component: () =>
          import(
            "@/components/TimeStudies/OutstandingRecords/OutStandingReports.vue"
          ),
      },
      {
        path: "view-completed-faculty-records/:id",
        name: "ViewCompletedFacultyRecords",
        component: () =>
          import(
            "@/components/TimeStudies/CompletedRecords/CompletedFacultyReports.vue"
          ),
      },
      {
        path: "view-rejected-faculty-records/:id",
        name: "ViewrejectedFacultyRecords",
        component: () =>
          import(
            "@/components/TimeStudies/RejectedRecords/RejectedFacultyReports.vue"
          ),
      },
      {
        path: "completed-records",
        name: "CompletedRecords",
        component: () =>
          import(
            "@/components/TimeStudies/CompletedRecords/CompletedRecords.vue"
          ),
      },
      {
        path: "view-completed-records/:id",
        name: "ViewCompletedRecords",
        component: () =>
          import(
            "@/components/TimeStudies/CompletedRecords/viewCompletedRecords.vue"
          ),
      },
      {
        path: "rejected-records",
        name: "RejectedRecords",
        component: () =>
          import(
            "@/components/TimeStudies/RejectedRecords/RejectedRecords.vue"
          ),
      },
      {
        path: "view-rejected-records/:id",
        name: "ViewRejectedRecords",
        component: () =>
          import(
            "@/components/TimeStudies/RejectedRecords/viewRejectedRecords.vue"
          ),
      },
      {
        path: "upcoming-periods",
        name: "UpcomingPeriods",
        component: () =>
          import(
            "@/components/TimeStudies/UpcomingRecords/UpcomingPeriods.vue"
          ),
      },
      {
        path: "view-upcoming-records/:id",
        name: "view-Upcoming-Records",
        component: () =>
          import(
            "@/components/TimeStudies/UpcomingRecords/viewUpcomingRecords.vue"
          ),
      },
      {
        path: "outstanding-periods",
        name: "MissingPeriods",
        component: () =>
          import("@/components/TimeStudies/MissingRecords/MissingPeriods.vue"),
      },
      {
        path: "view-outstanding-records/:id",
        name: "viewMissingPeriods",
        component: () =>
          import(
            "@/components/TimeStudies/MissingRecords/viewMissingPeriods.vue"
          ),
      },
      {
        path: "Edit-outstanding-records",
        name: "EditMissingRecords",
        component: () =>
          import("@/components/TimeStudies/MissingRecords/MissedEditsCom.vue"),
      },
      {
        path: "Edit-faculty-outstanding-records",
        name: "EditMissingFacultyRecords",
        component: () =>
          import(
            "@/components/TimeStudies/MissingRecords/MissedEditFaculty.vue"
          ),
      },
      {
        path: "enter-missing-periods",
        name: "EnterMissingPeriods",
        component: () =>
          import("@/components/TimeStudies/EnterMissingPeriods.vue"),
      },
      {
        path: "people",
        name: "People",
        component: () => import("@/components/People/NewPeopleCom.vue"),
      },
      {
        path: "administrators",
        name: "Administrator",
        component: () =>
          import("@/components/People/Administrator/AdministratorsCom.vue"),
      },
      {
        path: "create-super-admin",
        name: "CreateSuperAdmin",
        component: () =>
          import("@/components/NewRoleSystemAdmin/People/CreateSuperAdmin.vue"),
      },
      {
        path: "update-super-admin",
        name: "UpdateSuperAdmin",
        component: () =>
          import("@/components/NewRoleSystemAdmin/People/EditSuperAdmin.vue"),
      },
      {
        path: "reporter",
        name: "Reporter",
        component: () => import("@/components/People/Reporter/ReporterCom.vue"),
      },
      {
        path: "user/assistant",
        name: "UserAssistant",
        component: () =>
          import("@/components/People/Assistant/userAssistantComp.vue"),
      },
      {
        path: "/assistant",
        name: "Assistant",
        component: () =>
          import("@/components/People/Assistant/AssistantComp.vue"),
      },
      {
        path: "/supporter",
        name: "Supporter",
        component: () =>
          import("@/components/People/Supporter/SupporterCom.vue"),
      },
      {
        path: "/manager",
        name: "Manager",
        component: () => import("@/components/People/Manager/ManagerCom.vue"),
      },
      {
        path: "/supporter-edit",
        name: "SupporterEdit",
        props: true,
        component: () =>
          import("@/components/People/Supporter/SupporterEditCom.vue"),
      },
      {
        path: "/administrator-form",
        name: "administatorform",
        component: () =>
          import("@/components/People/Administrator/AdministatorForm.vue"),
      },
      {
        path: "/administrator-edit",
        name: "administatorEdit",
        component: () =>
          import("@/components/People/Administrator/AdministatorEditCom.vue"),
      },
      {
        path: "/supporter-form",
        name: "supporterform",
        component: () =>
          import("@/components/People/Supporter/SupporterForm.vue"),
      },
      {
        path: "/admin-edit-additional",
        name: "AdminEditAdditionl",
        component: () =>
          import("@/components/People/Supporter/SupporterEditAdditional.vue"),
      },
      {
        path: "/manager-edit-additional",
        name: "ManagerEditAdditionl",
        component: () =>
          import("@/components/People/Manager/ManagerEditAdditional.vue"),
      },
      {
        path: "/manager-form",
        name: "managerform",
        component: () => import("@/components/People/Manager/ManagerForm.vue"),
        // import("@/components/Help/HelpCom.vue"),
      },
      {
        path: "/reporter-form",
        name: "reporterform",
        component: () =>
          // import("@/components/People/Reporter/ReporterForm.vue"),
          import("@/components/People/User/CreateUser.vue"),
      },
      {
        path: "/assistant-form",
        name: "assistantform",
        component: () =>
          import("@/components/People/Assistant/CreateAssistant.vue"),
      },
      {
        path: "/reporter-edit",
        name: "ReporterEdit",
        component: () =>
          import("@/components/People/Reporter/ReporterEditCom.vue"),
      },
      {
        path: "/assistant-edit",
        name: "AssistantEdit",
        component: () =>
          import("@/components/People/Assistant/AssistantEditCom.vue"),
      },
      {
        path: "/reporter-edit-additional",
        name: "ReporterEditAdditionl",
        component: () =>
          import("@/components/People/Reporter/ReporterEditAdditional.vue"),
      },
      {
        path: "/assistant-edit-additional",
        name: "AssistantEditAdditionl",
        component: () =>
          import("@/components/People/Assistant/AssistantEditAdditional.vue"),
      },
      {
        path: "/manager-edit",
        name: "ManagerEdit",
        component: () => import("@/components/People/Manager/ManagerEdit.vue"),
      },
      // {
      //   path: "manage-form",
      //   name: "ManageForm",
      //   component: () => import("@/components/Forms/ManageForms.vue"),
      // },
      // {
      //   path: "manage-categories",
      //   name: "ManageCategories",
      //   component: () =>
      //     import("@/components/Forms/ManageCategories.vue"),
      // },
      // {
      //   path: "manage-activities",
      //   name: "ManageActivities",
      //   component: () => import("@/components/Forms/ManageActivities.vue"),
      // },
      // {
      //   path: "manage-time-periods",
      //   name: "ManageTimePeriods",
      //   component: () => import("@/components/Forms/ManageTimePeriods.vue"),
      // },
      {
        path: "/configuration-wizards",
        name: "ConfigurationWizards",
        component: () =>
          import(
            "@/components/SystemSettings/ConfigurationWizards/ConfigurationWizards.vue"
          ),
      },
      {
        path: "/password-management",
        name: "PasswordManagement",
        component: () =>
          import(
            "@/components/SystemSettings/PasswordManagement/PasswordManagement.vue"
          ),
      },
      {
        path: "/control-panel",
        name: "ControlPanel",
        component: () =>
          import("@/components/SystemSettings/ControlPanel/ControlPanel.vue"),
      },
      {
        path: "/admin-reports",
        name: "AdminReports",
        component: () =>
          import("@/components/Reports/AdminReports/AdminReports.vue"),
        // import("@/components/Help/HelpCom.vue"),
      },
      {
        path: "/summary-reports",
        name: "Summary-reports",
        component: () =>
          import("@/components/Reports/SummaryReports/SummaryReports.vue"),
        // import("@/components/Help/HelpCom.vue"),
      },
      {
        path: "/manage-roles",
        name: "ManageRoles",
        component: () =>
          import("@/components/Management/ManageRoles/ManageRoles.vue"),
      },
      {
        path: "/manage-permissions",
        name: "ManagePermissions",
        component: () =>
          import(
            "@/components/Management/ManagePermissions/ManagePermissions.vue"
          ),
      },

      {
        path: "custom-reports",
        name: "custom-reports",
        component: () =>
          // import("@/components/Reports/CustomReports/CustomReports.vue"),
          import("@/components/Reports/FinancialReports.vue"),
      },
      {
        path: "fringe-benefits",
        name: "fringe-benefits",
        component: () =>
          import("@/components/FringeBenefits/FringeBenefits.vue"),
      },
      {
        path: "help",
        name: "help",
        component: () => import("@/components/Help/HelpCom.vue"),
      },
      {
        path: "faculty",
        name: "faculty",
        component: () => import("@/components/FacultyCom.vue"),
      },
    ],
  },
  // {
  //     path: "/schedule-exam",
  //     name: "ScheduleComponent",
  //     component: () =>
  //         import ("@/components/ScheduleTest/TestType.vue"),

  // },
  // {
  //     path: "/customer-onboarding",
  //     name: "OnboardingComponent",
  //     component: () =>
  //         import ("@/components/CustomerOnboarding/AppointmentCom.vue"),

  // },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!isLoggedIn()) {
      next("/signin");
    } else {
      next();
    }
  } else if (to.meta.allowAnonymous && isLoggedIn()) {
    next("/");
  } else if (!to.meta.allowAnonymous && !isLoggedIn()) {
    next("/signin");
  } else {
    next();
  }
});

export default router;
